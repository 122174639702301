.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fc-day-today {
  background-color: #fcfcfc !important;
  border: 1px solid var(--bs-gray-400) !important;
  color: black;
}

.fc-col-header-cell.fc-day-today>.fc-scrollgrid-sync-inner {
  background-color: #003C64 !important;
  color: white !important;
}

.fc-prev-button {
  background-color: #003C64 !important;
  border-color: #003C64 !important;
  border-radius: 0.5rem !important;
}

.fc-next-button {
  background-color: #003C64 !important;
  border-color: #003C64 !important;
  border-radius: 0.5rem !important;
}

.fc-toolbar-title {
  color: #003C64 !important;
}

.fc-col-header {
  background-color: #003C64 !important;
  color: #ECEDE8;
  padding: 0.3rem;
  margin-bottom: 5px !important;
  /*box-shadow: 0 10px 5px -10px #003C64;*/
}

.fc-event-main {
  text-align: center !important;
  ;
  margin-left: auto;
}

.fc-event-time {
  padding-top: 20px !important;
  ;
}

.fc-event {
  border-radius: 20px !important;
  ;
}

.fc-scrollgrid {
  border-color: none !important;
}

.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
}

.fc table {
  border-collapse: separate !important;
}

.fc {
  height: 70vh;
  border-radius: 20px !important;
}

.fc-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.fc-button:focus,
.fc-button:hover {
  text-decoration: none;
}

.fc-state-active {
  background-color: rgba(158, 158, 158, 0.2);
}

.fc-state-highlight {
  opacity: 0;
  border: none;
}

.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: .65;
  left: 5% !important;
  right: 5% !important;
}

.fc-event {
  font-size: inherit !important;
  font-weight: bold !important;
}

.fc-col-header {
  margin-bottom: 5px;
}

.fc td,
.fc th {
  border-style: none !important;
  border-bottom: #282c34 !important;
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important;
}

.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: .25 !important;
}

.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

.fc-theme-bootstrap5 .fc-scrollgrid {
  border: none;
}

.fc-timegrid-now-indicator-line {
  border-color: #F07D00 !important;
  border-width: 0.1rem !important;
}

.fc-timegrid-now-indicator-arrow {
  border-color: #F07D00 !important;
}

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
  border-width: 5px 0 5px 6px !important;
  left: 0 !important;
}

.fc-scroller-harness .fc-col-header {
  border-radius: 50px;
}

.fc .fc-timegrid-slot-minor {
  border-bottom-style: dotted !important;
}

.react-datepicker__time-list-item--disabled, .react-datepicker__day--weekend, .react-datepicker__day-name:nth-of-type(n + 6){
  display: none !important;
}

.react-datepicker__day-name, .react-datepicker__day{
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.fc .fc-scrollgrid-section-liquid>td {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.fc-scroller-harness .fc-col-header {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

#workplacebtn {
  border-color: #003C64 !important;
  color: #003C64 !important;
}

#workplacebtn:hover {
  background-color: #003C64 !important;
  color: #FFFFFF !important;
}

.react-datepicker__header {
  background-color: #282D37 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #ECEDE8 !important;
}

.react-datepicker__day--selected {
  background-color: #F07D00 !important;
}

.react-datepicker__day--selected:hover {
  background-color: #e07503 !important;
}

li.react-datepicker__time-list-item--selected {
  background-color: #F07D00 !important;
}

li.react-datepicker__time-list-item--selected:hover {
  background-color: #e07503 !important;
}

.accordion-button {
  justify-content: center;
}

.accordion-body {
  text-align: center;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #e07503 !important;
}

.accordion-button::after {
  display: none;
}

.workplacecard {
  border-radius: 1.5rem !important;
}

.accordion-flush .accordion-item {
  background-color: transparent !important;
}

/*
.workplacecards .col .workplacecard{
  border-top-left-radius: 5rem !important;
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: 5rem !important;
  border-bottom-left-radius: inherit !important;
}

.workplacecards .col:nth-child(2n) .workplacecard{
  border-bottom-left-radius: 5rem !important;
  border-bottom-right-radius: inherit !important;
  border-top-left-radius: inherit !important;
  border-top-right-radius: 5rem !important;
}

*/

.dropdown-toggle::after {
  display: none !important;
}

@media only screen and (max-width: 810px) {
  .background-image {
    display: none !important;
  }

  .logo {
    height: 5vh !important;
  }

  .card-body h5 {
    font-size: 1em !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .container {
    height: calc(100vh - 6rem) !important;
  }

  .card .card-body {
    margin: 0px !important;
    width: 80vw;
  }
  
  div .card button {
    font-size: 1rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .card p {
    margin-bottom: 1rem !important;
  }

  nav .navbar-brand,
  .bg-image {
    display: none;
  }

  .bg-image {
    mask-image: linear-gradient(to left, transparent 100%, white);
  }

  nav .container-fluid {
    height: 100% !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  nav .nav-item a {
    margin-left: 0px !important;
    text-align: left !important;
  }

  .navbar-text strong {
    margin-left: 0px !important;
  }

  .positioning {
    width: 80vw !important;
    position: inherit;
    top: 8rem !important;
  }

  .positioning .h1 {
    font-size: 2rem !important;
  }

  .container .col p {
    font-size: 1.2rem !important;
  }

  .fc-toolbar-chunk button {
    font-size: 0.7rem !important;
  }

  .fc-toolbar-chunk h2 {
    text-align: center !important;
    font-size: 1.5rem !important;
  }

  .card .row .informationCard {
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
    align-content: center !important;
  }

  .informationCard h6,
  .informationCard p {
    font-size: 0.8rem !important;
  }

  .fc-event-main {
    font-size: 0.8rem !important;
  }

  .fc-event-time {
    color: transparent !important;
  }

  .inputContainer {
    height: 100% !important;
  }
  .card .card-body{
    width: 100% !important;
  }
  #workplacebtn{
    padding: 0.5rem 1.5rem !important;
  }
  .card .card-body .card-title{
    font-size: 1.5rem !important;
  }
  .blackProfileCard{
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .container-fluid .row{
    transform: none !important;
    position:unset !important;
  }
  .card-body form .btn{
    margin-left:0px !important;
    margin-right:0px !important;
  }
  .container-fluid .row img{
    height: 3rem !important;
  }
  form .row .my-5{
    margin-top: 0rem !important;
    margin-bottom: 2rem !important;
  }
  .card .p-5{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  div .signUpCard{
    margin-top:1rem !important;
    margin-bottom:1rem !important;
  }
  .navbar-nav .dropdown, .navbar-nav .navbar-text{
    margin-left: 0.5rem !important;
  }
  .signInCard, div .signUpCard{
    border-radius: 2rem !important;
  }
  .signInCard .card-body{
    padding-left: auto !important;
  }

  .container .profileMargin, .container .headerText, .blackProfileCard img{
    display: none !important;
  }
  .blackProfileCard h5{
    margin-top: 2rem !important;
  }
  .blackProfileCard p{
    margin-bottom: 2rem !important;
  }

  .informationCard .col h6, .informationCard .col p{
    margin-bottom: 0 !important;
  }
  .informationCard .card-body hr, .personalInformation .col, .companyInformation .col{
    margin-bottom: 1.5rem !important;
  }

  .personalInformation h6, .companyInformation h6{
    font-size: 1.2rem !important;
  }

  .personalInformation .col p, .companyInformation .col p{
    font-size: 1rem !important;
  }

  .reserveFilter .col p {
    margin-top: 1rem !important;
  }

  .reserveFilter .col select, .reserveFilter .col input{
    margin-top: 0.5rem !important;
  }

  .LogoAd{
    visibility: hidden !important;
    height:0;
  }

  .SignUpRow{
    position: static !important;
    transform: none !important;
  }

  .card-body p{
    margin: 0.5rem 20%;
    white-space: pre-line !important;
  }

}

.msAuthBtn div{
  display:inline-flex !important;
}